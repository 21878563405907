/**
 * @name getFirstFocusableElement
 * @returns The first focusable element in an element
 * @param {Element} element - The element you wish to check
 */
function getFirstFocusableElement(element) {
    return element.querySelector('button, [href], [tabindex]:not([tabindex="-1"])');
}

/**
 * @name moveFocus
 * @desc Navigate to and focus the element at the index you pass in. This calculates if you're at either edge and
 * if you are selects the correct next element.
 * @param {HTMLCollection} menuListItems - The list items you want to navigate
 * @param {Number} index - The index you would like move to
 */
function moveFocus(menuListItems, index) {
    let focusIndex;

    if (index >= menuListItems.length) {
        focusIndex = 0;
    } else if (index < 0) {
        focusIndex = menuListItems.length - 1;
    } else {
        focusIndex = index;
    }

    getFirstFocusableElement(menuListItems[focusIndex]).focus();
}

/**
 * @name getCurrentFocusIndex
 * @desc Looks to see if there is an item in the list that is already focused
 * @returns The index of the focused element if there is one focused
 * @param {HTMLCollection} menuListItems - The list items you want to navigate
 */
function getCurrentFocusIndex(menuListItems) {
    return menuListItems.findIndex(listItem =>
        listItem.contains(document.activeElement)
    );
}

/**
 * @name onKeyDown
 * @desc Takes a keypress and responds by correctly changing focus on the menu
 * @param {HTMLCollection} menuListItems - The list items you want to navigate
 * @param {KeyboardEvent} event - The keypress event
 */
function onKeyDown(menuListItems, event) {
    const currentIndex = getCurrentFocusIndex(menuListItems);

    const keys = {
        left: 37,
        up: 38,
        right: 39,
        down: 40
    };

    const keyLeftUpHandler = () => {
        event.preventDefault();
        moveFocus(menuListItems, currentIndex - 1);
    };

    const keyRightDownHandler = () => {
        event.preventDefault();
        moveFocus(menuListItems, currentIndex + 1);
    };

    const keyHandlers = {
        [keys.left]: keyLeftUpHandler,
        [keys.up]: keyLeftUpHandler,
        [keys.right]: keyRightDownHandler,
        [keys.down]: keyRightDownHandler
    };

    if (keyHandlers[event.keyCode]) {
        keyHandlers[event.keyCode]();
    }
}

const menuElement = document.querySelector('[data-client-hook="accessible-menu"]');
if (menuElement !== null) {
    const menuListItems = menuElement.children;

    Array.prototype.forEach.call(menuListItems, listItem =>
        listItem.addEventListener('keydown', event => onKeyDown(menuListItems, event))
    );
}
